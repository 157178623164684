import { isMobile } from "react-device-detect"
import { imagenes } from "../asset/asset"

export const ParkSession =()=>{
  return(
      <>
        {isMobile ? <ParkSessionMobile/> : <PcParkSession  />}
      </>
  )
}
const ParkSessionMobile =()=>{
  return(
    <div className="m-0 p-0">
    <header className="w-100 m_h_100vh m_header_ps_mobile text-white">
      <div className="m_h_100vh w-100 d-flex flex-column justify-content-between align-items-center ">
        <div className="text-start w-100">
          <span className="m_font_primary fs-1 ms-5 fst-italic">-</span>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center w-100">
          <h2 translate="no" className="m_font_primary mb-3 text-center">PARK SESSION</h2>
          <p className="fs-1 fw-bold fst-italic pt-5 w-75 text-center">HASTA QUE EL UNDER SUENE EN LA LUNA</p>
        </div>
        <div className="">
          <img className="w-100" src={imagenes.psBGbot} alt="banner park Session" />
        </div>
      </div>  
    </header> 

    <div style={{ position: 'relative' }}>
      <img className="w-100" src={imagenes.bannerQuiebrePsMobile} alt="" />
      <button className="border-0 rounded-2 py-1 px-2 fs-4 m_font_primary" style={{
          position: 'absolute',
          top: '65%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1, 
        }}><a href="https://drive.google.com/drive/folders/1ep9pmWLbYWMGoWnuXE8zg5iYKGSGrB-y" target="_blank" className="text-black">Revivir Park Session</a>
        </button>
    </div>
    <div className="d-flex flex-column">
      <img className="w-100" src={imagenes.bannerQuiebrePStop} alt="" />
      <div className="w-100 d-flex jusify-content-between align-items-center justify-content-center align-items-center">
        <div className="w-75 d-flex justify-content-around align-items-center my-5 text-white flex-column">
          <div className="m_w_70 d-flex flex-column m_bg_primary mb-4">
            <img className="w-100 bg-danger" src={imagenes.parkeandola} alt="parkeandola" />
            <a href="https://www.instagram.com/p/C_RsfG9s_3z/" target="_blank" className="m_font_primary text-end pe-3 fs-4 text-white ">PARKEANDOLA</a>
          </div>
          <div className="m_w_70 d-flex flex-column m_bg_primary ">
            <img className="w-100 bg-danger " src={imagenes.parkSessionJUL} alt="parkSession julio" />
            <a href="https://drive.google.com/drive/folders/1ep9pmWLbYWMGoWnuXE8zg5iYKGSGrB-y" target="_blank" className="m_font_primary text-end pe-3 fs-4  text-white">REVIVI LA ULTIMA</a>
          </div>     
        </div>
      </div>
    </div>
  </div>
  )
}
const PcParkSession =()=>{
  return(
    <div className="m-0 p-0">
      <header className="w-100 m_h_100vh m_header_ps text-white">
        <div className="m_h_100vh w-100 d-flex flex-column justify-content-between align-items-center ">
          <div className="text-start w-100">
            <span className="m_font_primary fs-1 ms-5 fst-italic">-</span>
          </div>
          <div className="text-center">
            <h2 translate="no" className="m_font_primary mb-5">PARK SESSION</h2>
            <p className="fs-2 fw-bold fst-italic">HASTA QUE EL UNDER SUENE EN LA LUNA</p>
          </div>
          <div className="">
            <img className="w-100" src={imagenes.psBGbot} alt="banner park Session" />
          </div>
        </div>  
      </header> 

      <div style={{ position: 'relative' }}>
        <img style={{ width: '100%' }} src={imagenes.bannerQuiebrePS} alt="" />
        <button className="border-0 rounded-2 py-1 px-2 fs-4 m_font_primary" style={{
          position: 'absolute',
          top: '65%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1, 
        }}><a href="https://drive.google.com/drive/folders/1ep9pmWLbYWMGoWnuXE8zg5iYKGSGrB-y" target="_blank" className="text-black">Revivir Park Session</a>
        </button>
      </div>
      <div className="d-flex flex-column">
        <img className="w-100" src={imagenes.bannerQuiebrePStop} alt="" />
        <div className="w-100 d-flex jusify-content-between align-items-center justify-content-center align-items-center">
          <div className="w-75 d-flex justify-content-around my-5 text-white">
            <div className="m_w_30 d-flex flex-column m_bg_primary">
              <img className="w-100 bg-danger " src={imagenes.parkeandola} alt="parkeandola" />
              <a href="https://www.instagram.com/p/C_RsfG9s_3z/" target="_blank" className="m_font_primary text-end pe-3 fs-4 text-white ">PARKEANDOLA</a>
            </div>
            <div className="m_w_30 d-flex flex-column m_bg_primary ">
              <img className="w-100 bg-danger" src={imagenes.parkSessionJUL} alt="parkSession julio" />
              <a href="https://drive.google.com/drive/folders/1ep9pmWLbYWMGoWnuXE8zg5iYKGSGrB-y" target="_blank" className="m_font_primary text-end pe-3 fs-4  text-white">REVIVI LA ULTIMA</a>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  )
}