import { imagenes } from "../asset/asset"
import { useContext, useRef, useState } from "react";

import { BiPlay } from "react-icons/bi";
import { BiPause } from "react-icons/bi";
import { FaVolumeXmark } from "react-icons/fa6";
import { IoVolumeLow } from "react-icons/io5";
import { IoVolumeMedium } from "react-icons/io5";
import { IoVolumeHigh } from "react-icons/io5";
import { MyContext } from "../context/Context";

export const ReproductorFixedTopAll =()=>{
  const { isPlaying, handlePlayPause, audioRef } = useContext(MyContext);
  const [volume, setVolume] = useState(50);
  const [isMuted, setIsMuted] = useState(false);

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    setVolume(newVolume);
    audioRef.current.volume = newVolume / 100;
    if (newVolume > 0 && isMuted) {
      setIsMuted(false);
    }
  };

  const toggleMute = () => {
    if (isMuted) {
      audioRef.current.volume = volume / 100;
      setIsMuted(false);
    } else {
      audioRef.current.volume = 0;
      setIsMuted(true);
    }
  };

  const renderVolumeIcon = () => {
    if (isMuted || volume < 1) {
      return <FaVolumeXmark size={30} color="white" onClick={toggleMute} />;
    } else if (volume <= 33) {
      return <IoVolumeLow size={35} color="white" onClick={toggleMute} />;
    } else if (volume <= 65) {
      return <IoVolumeMedium size={35} color="white" onClick={toggleMute} />;
    } else {
      return <IoVolumeHigh size={35} color="white" onClick={toggleMute} />;
    }
  };

  return (
    <div className="m_radio-player d-flex w-100 align-items-center">
      <div className="d-flex bg-dark rounded-4 pe-3">
        <div className={`${isPlaying ? "m_live_button_activo_fixed" : "m_live_button_fixed"} d-flex justify-content-center align-items-center rounded-4`}>
          <div className="m_w_30 h-100 d-flex justify-content-center align-items-center bg-white m_live_button_circle">
            <button className="border-0 " onClick={handlePlayPause}>
              {isPlaying ? <BiPause size={30} color="black" /> : <BiPlay size={30} color="black" />}
            </button>
          </div>
          <div className={`m_w_70 h-100 ${ isPlaying ? "m_bg_primary" : "m_bg_gray"} d-flex justify-content-center align-items-center m_live_button_enVivo`}> 
            <span className="text-white fs-5 fw-bold">EN VIVO</span>
          </div>
        </div>
        <div className="controls d-flex justify-content-center align-items-center">
          {renderVolumeIcon()}
          <input type="range" min="0" max="100" value={volume} onChange={handleVolumeChange}/>
        </div>
      </div>

    </div>
  );
};
export const NavProgramacion = () => {
  return(
    <div className="m_nav_programacion text-white fw-bold">
        <p className="">Lunes a Viernes <span className="m_color_primary">Demasiado Tarde</span> 10:00 <span className="m_color_primary">Augusto Ochoa</span></p>
        <p>||</p>
        <p className="">MARTES <span className="m_color_primary">UN ESPIA EN LA CASA DEL AMOR</span> 22:00 <span className="m_color_primary">MARTÍN EMILIO CAMPOS</span></p>
        <p>||</p>
        <p className="">MIÉRCOLES<span className="m_color_primary">LA ISLA DE LOS GATOS</span> 22:00 <span className="m_color_primary">RODRIGO ARTAL</span></p>
        <p>||</p>
        <p className="">SÁBADO<span className="m_color_primary">OTRO SIGLO</span> 10:00 <span className="m_color_primary">CAMILA ARGUELLO Y CATALINA CARAMUTI</span></p>
      </div>
  )
}

export const LiveButton = () => {
  const { isPlaying, handlePlayPause } = useContext(MyContext);
  return (
    <div className={`${isPlaying ? "m_live_button_activo" : "m_live_button"} d-flex justify-content-center align-items-center rounded-4`}>
      <div className="m_w_30 h-100 d-flex justify-content-center align-items-center bg-white m_live_button_circle">
        <div className={`${ isPlaying ? "m_bg_primary" : "m_bg_gray"} m_circle`}></div>
      </div>
      <div className={`m_w_70 h-100 ${ isPlaying ? "m_bg_primary" : "m_bg_gray"} d-flex justify-content-center align-items-center m_live_button_enVivo`}> 
        <span className="text-white fs-4 fw-bold">EN VIVO</span>
      </div>
    </div>
  );
};