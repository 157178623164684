import React, { useState, useContext } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { MyContext } from '../context/Context';
import { useNavigate } from 'react-router-dom';
import { EmpityBox } from '../componentes/Decoracion';

export const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { setAdmLogeado, setUserEmail } = useContext(MyContext);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      setAdmLogeado(true);
      setUserEmail(email);
      navigate("/dashboard");
      setError('');
    } catch (error) {
      console.error('Error logging in:', error);
      setError('Invalid email or password');
    }
  };

  return (
    <div className="container d-flex flex-column justify-content-center align-items-center">
      <EmpityBox />
      <form onSubmit={handleLogin} className="form-signin d-flex flex-column w-50 mt-5 pt-4">
        <h2 className="form-signin-heading text-white mb-3">Iniciar Sesión</h2>
        <div className="form-group d-flex ">
          <label className='text-white fs-4 m_w_40' htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control"
            required
          />
        </div>
        <div className="form-group d-flex my-3">
          <label className='text-white fs-4 m_w_40' htmlFor="password">Contraseña:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control"
            required
          />
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        <div className="">
          <button type="submit" className="btn btn-primary m_w_30">Loguearse</button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
