import parkSessionBgHome from"./parkSessinBG2.jpg";
import nacerSol from "./nacerSol.jpg";
import parkSessionBanner from "./parkSessionBanner.jpg";
import psBGbot from "./psBGbot.png";
import bannerQuiebrePS from "./bannerQuiebrePS.jpg";
import bannerQuiebrePsMobile from "./bannerQuiebrePsMobile.jpg";
import bannerQuiebrePStop from "./bannerQuiebrePStop.png";
import parkSessionJUL from "./parkSessionJUL.png";
import parkeandola from "./parkeandola.jpg";
import parkSessinBGMobile from "./parkSessinBGMobile.png";

export const imagenes = {
  bannerQuiebrePsMobile,
  parkSessinBGMobile,
  parkeandola,
  parkSessionJUL,
  bannerQuiebrePStop,
  bannerQuiebrePS,
  psBGbot,
  parkSessionBgHome,
  nacerSol,
  parkSessionBanner
}