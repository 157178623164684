import { useContext, useState } from "react";
import { isMobile } from "react-device-detect"
import { useNavigate } from "react-router-dom"
import { MyContext } from "../context/Context";
import { getAuth, signOut } from "firebase/auth";

//icons
import { IoCloseSharp } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import { CiInstagram } from "react-icons/ci";
import { FaYoutube } from "react-icons/fa";
import { IoLogoGooglePlaystore } from "react-icons/io5";

export const NavBurger =()=>{
  return(
    <>
      {isMobile ? <NavMobileBurgerMobile/> : <NavMobileBurgerPC/>}
    </>
  )
}
 const NavMobileBurgerPC =()=> {
  const [isOpen, setIsOpen] = useState(false);
  const {admLogeado} = useContext(MyContext);
  const navigate = useNavigate("/");
  const auth = getAuth();

  const handleLogout = async () => {
    console.log("Cerrando sesión");
    try {
      await signOut(auth);
      navigate('/login');
      console.log("Sesión cerrada exitosamente");
    } catch (error) {
      console.error("Error al cerrar sesión: ", error);
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-100">
      <div className={`m_menu_desplegable flex-column justify-content-around align-items-center p-5 m_bg_primary ${isOpen ? 'd-flex' : 'd-none'}`}>

        <h2 className="text-white m_font_primary fs-1">PARK</h2>
        <div className="d-flex flex-column justify-content-center  h-75 w-75">
          <button className="fs-1 mb-2 border-0 rounded-2 " onClick={()=>{navigate('/')
            setIsOpen(false)}}>HOME</button>
          <button className="fs-1 my-4 border-0 rounded-2 " onClick={()=>{navigate('/ParkSession')
            setIsOpen(false)}}>PARK SESSION</button>
          <button className="fs-1 mt-2 border-0 rounded-2 " onClick={()=>{navigate('/notas')
            setIsOpen(false)}}>NOTAS</button>
          <button className={`fs-1 ${admLogeado ? 'mt-3' : 'd-none'}`}>Admin</button>
          <button className={`fs-1 ${admLogeado ? '' : 'd-none'}`} onClick={()=> handleLogout()}>Desconectarme</button>
        </div>
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex">
            <a className="text-white m_bg_primary p-2 pb-3 rounded-5" href="https://www.instagram.com/park.fm" target="_blank"><CiInstagram className="" size={35}/></a> 
            <a className="text-white m_bg_primary p-2 pb-3 rounded-5 mx-4" href="https://www.youtube.com/@SomosPark" target="_blank"><FaYoutube className="" size={35}/></a>
            <a className="text-white m_bg_primary p-2 pb-3 ps-2 rounded-5" href="https://play.google.com/store/apps/details?id=com.app.radiopark&pli=1" target="_blank"><IoLogoGooglePlaystore className="" size={35}/></a>
          </div>
          <p className="text-white">PARK - Una señal en la tierra. Derechos reservados ©2024</p>
        </div>
      </div> 
      <button className="m_menu_burger m_bg_primary rounded-2 p-1" onClick={toggleMenu}>
        {isOpen ? <IoCloseSharp size={30}  color="white"/> : <GiHamburgerMenu size={30} color="white"/> }
      </button>
    </div>
  )
}
 const NavMobileBurgerMobile =()=> {
  const [isOpen, setIsOpen] = useState(false);
  const {admLogeado} = useContext(MyContext);
  const navigate = useNavigate("/");
  const auth = getAuth();

  const handleLogout = async () => {
    console.log("Cerrando sesión");
    try {
      await signOut(auth);
      navigate('/login');
      console.log("Sesión cerrada exitosamente");
    } catch (error) {
      console.error("Error al cerrar sesión: ", error);
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="m_nav_container_mobile">
      <div className={`m_menu_desplegableMobile flex-column justify-content-around align-items-center p-5 m_bg_primary ${isOpen ? 'd-flex' : 'd-none'}`}>

        <h2 className="text-white m_font_primary fs-1">PARK</h2>
        <div className="d-flex flex-column justify-content-center  h-75 w-75">
          <button  className="fs-1 mb-2 border-0 rounded-2 " 
          onClick={()=>{navigate('/')
          setIsOpen(false)}}>HOME</button> 
          <button  className="fs-1 my-4 border-0 rounded-2 " onClick={()=>{
            navigate('/ParkSession')
            setIsOpen(false)}}>PARK SESSION</button>
          <button  className="fs-1 mt-2 border-0 rounded-2 " onClick={()=>{
            navigate('/notas')
            setIsOpen(false)}}>NOTAS</button>
          <button  className={`fs-1 ${admLogeado ? 'mt-3' : 'd-none'}`}>Admin</button>
          <button  className={`fs-1 ${admLogeado ? '' : 'd-none'}`} onClick={()=> handleLogout()}>Desconectarme</button>
        </div>
        <div className="d-flex flex-column align-items-center text-center">
          <div className="d-flex">
            <a className="text-white m_bg_primary p-2 pb-3 rounded-5" href="https://www.instagram.com/park.fm" target="_blank"><CiInstagram className="" size={35}/></a> 
            <a className="text-white m_bg_primary p-2 pb-3 rounded-5 mx-4" href="https://www.youtube.com/@SomosPark" target="_blank"><FaYoutube className="" size={35}/></a>
            <a className="text-white m_bg_primary p-2 pb-3 ps-2 rounded-5" href="https://play.google.com/store/apps/details?id=com.app.radiopark&pli=1" target="_blank"><IoLogoGooglePlaystore className="" size={35}/></a>
          </div>
          <p className="text-white">PARK - Una señal en la tierra. Derechos reservados ©2024</p>
        </div>
      </div> 
      <button className="m_menu_burger m_bg_primary rounded-2 p-1" onClick={toggleMenu}>
        {isOpen ? <IoCloseSharp size={30}  color="white"/> : <GiHamburgerMenu size={30} color="white"/> }
      </button>
    </div>
  )
}
